<template>
  <el-container>
    <el-header>
      <el-form style="text-align: center" :inline="true" :model="queryValue">
        <el-form-item label="年级:" size="small">
          <el-checkbox-group v-model="queryValue.grade">
            <el-checkbox v-for="s in selections.grades" :key="s.code" :label="s.code">{{ s.value }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="类型:" size="small">
          <el-checkbox-group v-model="queryValue.nature">
            <el-checkbox v-for="s in selections.natures" :key="s.code" :label="s.code">{{ s.value }}
            </el-checkbox>
          </el-checkbox-group>

        </el-form-item>

        <el-form-item label="梯队:" size="small">
          <el-checkbox-group v-model="queryValue.level">
            <el-checkbox v-for="s in selections.levels" :key="s.code" :label="s.code">{{ s.value }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="区域:" size="small">
          <el-select
              v-model="queryValue.area"
              allow-create
              default-first-option
              filterable
              collapse-tags
              clearable
              collapse-tags-tooltip
              :max-collapse-tags="1"
          >
            <el-option
                v-for="k in selections.areas"
                :key="k.code"
                :label="k.value"
                :value="k.code"
            />

          </el-select>
        </el-form-item>

        <el-form-item size="small">
          <el-button @click="query" icon="Search" type="primary" :loading="queryValue.loading">查询</el-button>
        </el-form-item>

      </el-form>
    </el-header>
    <el-main>
      <el-table
          :data="resp.list"
          border
          max-height="60vh"
          style="width: 100%"
      >
        <el-table-column prop="name" label="学校" :fixed="true"></el-table-column>
        <el-table-column prop="grade" label="年级" width=80></el-table-column>
        <el-table-column prop="nature" label="类别" width=80></el-table-column>
        <el-table-column prop="level" label="梯队" width=80></el-table-column>
        <el-table-column prop="addr" label="地址"></el-table-column>
        <el-table-column prop="detail" label="详情" width=100>
          <template #default="scope">
            <a style="color: #409eff" cursor="pointer" @click="openDetail(scope.row)">查看详情</a>
          </template>
        </el-table-column>

      </el-table>
      <baidu-map style="width: 100% ;height: 500px;margin: auto" :center="map.center" :zoom="map.zoom"
                 :scrollWheelZoom="true">
        <bm-marker v-for="k in resp.addr" :key="k.name" :position="{lng: k.lng,lat: k.lat}" :dragging="false"
                   @click="clickPoint(k)">
          <bm-label :content="mergeK(k)" :labelStyle="{color: 'red', fontSize : '11px'}"
                    :offset="{width: -35, height: 30}"/>
        </bm-marker>

<!--          <bm-polygon :path="tPoints" stroke-color="blue" :stroke-opacity="0.5" :stroke-weight="2" :editing="true"  />-->

      </baidu-map>
    </el-main>

  </el-container>
</template>

<script>
import {ElMessage} from 'element-plus'
import axios from 'axios'

import {BaiduMap,  BmLabel, BmMarker} from "vue-baidu-map-3x";

export default {
  name: 'App',
  components: {  BmLabel, BmMarker, BaiduMap},
  mounted() {

  },
  methods: {
    mergeK(k){
      return k.name + k.alias
    },
    openDetail(row) {
      console.log(row)
      window.open(row.detail)
    },
    clickPoint(data) {
      var that = this
      that.map.center = {lng: data.lng, lat: data.lat}
      that.map.zoom = 18
      that.map.choose = data.name
      console.log(data)
    },
    query() {
      var that = this
      if (that.queryValue.area.length === 0) {
        ElMessage({showClose: true, message: '请选择要查询你那个区域的学校。', type: 'error'})
        return
      }
      console.log(that.queryValue)
      that.queryValue.loading = true
      axios.get('https://api.zhjsmy.com/school/query' +
          '?grade=' + that.queryValue.grade.join(',') +
          '&area=' + that.queryValue.area +
          '&level=' + that.queryValue.level.join(',') +
          '&nature=' + that.queryValue.nature.join(',')
      ).then(function (response) {
        console.log(response)
        that.resp.list = response.data.response.list
        that.resp.addr = response.data.response.addr
        that.map = response.data.response.map
      }).catch(function (error) {
        ElMessage({showClose: true, message: error, type: 'error'})
      })
      that.queryValue.loading = false
    }

  },
  data() {
    return {
      // tPoints:[
      //   {
      //     lng: 121.506377,
      //     lat: 31.245105
      //   },
      //   {
      //     lng: 120.506377,
      //     lat: 31.245105
      //   },
      //   {
      //     lng: 120.506377,
      //     lat: 30.245105
      //   },
      //   {
      //     lng: 121.506377,
      //     lat: 30.245105
      //   }
      // ],
      map: {
        choose: '',
        zoom: 15,
        center: {
          lng: 121.506377,
          lat: 31.245105
        }
      },
      resp: {
        list: [],
        addr:[]
      },
      selections: {
        areas: [
          {code: 2, value: '浦东'},
          {code: 3, value: '徐汇'},
          {code: 4, value: '闵行'},
          {code: 5, value: '杨浦'},
          {code: 6, value: '虹口'},
          {code: 7, value: '长宁'},
          {code: 8, value: '宝山'},
          {code: 9, value: '静安'},
          {code: 10, value: '黄浦'},
          {code: 11, value: '普陀'}
        ],
        grades: [
          {code: 4, value: '小学'},
          {code: 5, value: '初中'}
        ],
        natures: [
          {code: 1, value: '民办'},
          {code: 2, value: '公办'}
        ],
        levels: [
          {code: 1, value: '一梯队'},
          {code: 2, value: '二梯队'},
          {code: 3, value: '三梯队'},
        ]
      },
      queryValue: {
        grade: [],
        area: '',
        nature: [],
        level: [],
        loading: false
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

bm-view {
  height: 400px;
  width: 100%;
}
</style>
