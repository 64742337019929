import {createApp} from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElIcons from '@element-plus/icons-vue'
import BaiduMap from 'vue-baidu-map-3x'

const app = createApp(App)
app.use(ElementPlus, {
    locale: zhCn
})
app.use(BaiduMap, {
    ak: 'MSMUOOkTBiEZC4TGKlu36VZvBj2GIGfp'
})
Object.keys(ElIcons).forEach((key) => {
    app.component(key, ElIcons[key])
})
app.mount('#app')
